
import axios from 'axios';
export default {
    
    async createFines(params)  {
        return await axios.post(`fines/create` , params)
    },
    async createFinesList(params)  {
        return await axios.post(`fines/create/list` , params)
    },
    async updateFinesColumn(column , value , data)  {
        return await axios.put(`fines/update_list?${column}=${value}` , data)
    },
    async deleteFinesList(list)  {
        return await axios.delete(`fines/delete_list` , {
            data: {
                list:list
            }
        })
    },
    async reportFines(column , value)  {
        return await axios.get(`fines/report?${column}=${value}`)
    },
    async getAllFines()  {
        return await axios.get(`fines/all`)
    },
    async getOneFines(fine_id)  {
        return await axios.get(`fines/all/${fine_id}`)
    },
    async getFinesByColumn(column , value)  {
        return await axios.get(`fines/filter?column=${column}&value=${value}`)
    },
    async deleteFines(fine_id)  {
        return await axios.delete(`fines/delete/${fine_id}`)
    },
    async updateFines(fine_id , params)  {
        return await axios.put(`fines/update/${fine_id}` , params)
    }
}